import {
  ref,
  reactive,
  getCurrentInstance,
  onMounted,
  watch
} from 'vue'
import {
  claimUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers,
  minValue
} from '@vuelidate/validators'
import {
  useRoute
} from 'vue-router'

export default {
  name: 'ClaimAndPaymentForm',
  emits: ['reloadData', 'closeSidebar'],
  props: ['dataEdit', 'isPaymentKlaim', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const route = useRoute()
    const Id = ref('')
    const title = ref('Tambah Klaim Agen')
    const listKategori = ref([])
    const {
      dataEdit,
      isPaymentKlaim
    } = reactive(props)
    const dataForm = reactive({
      kategoriKlaimId: null,
      nominal: 0,
      keterangan: null
    })
    const rules = {
      kategoriKlaimId: {
        required: helpers.withMessage('Nama klaim harus diisi', required)
      },
      nominal: {
        required: helpers.withMessage('Nominal harus diisi', required),
        minValue: helpers.withMessage('Nominal tidak boleh 0', minValue(1))
      }
    }

    const v$ = useVuelidate(rules, dataForm)
    const submitted = ref(false)

    const defineDataForm = (val: any) => {
      console.log('val', val)
      if (isPaymentKlaim) {
        title.value = 'Pembayaran Klaim'
      } else {
        title.value = 'Tambah Klaim Agen'
      }
      Id.value = val.Id
      dataForm.kategoriKlaimId = val.KategoriKlaimId
      dataForm.nominal = val.Nominal
      dataForm.keterangan = val.Keterangan
    }

    const getListKlaim = async () => {
      const filter = {
        filter: 'IsActive eq true'
      }
      const response = await claimUseCase.getAll(filter)
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: $strInd.toast.errorAllData,
          group: 'bc',
          life: 1500
        })
      } else {
        listKategori.value = response.result
      }
      store.dispatch('hideLoading')
    }

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const submitProccess = async () => {
      store.dispatch('showLoading')
      const data = {
        KategoriKlaimId: dataForm.kategoriKlaimId,
        AgenId: dataEdit.AgenId,
        Keterangan: dataForm.keterangan,
        Nominal: dataForm.nominal,
        IsPayment: false,
        Total: dataEdit.Total + dataForm.nominal
      }
      if (isPaymentKlaim) {
        data.IsPayment = true
        data.Total = dataEdit.Total - dataForm.nominal
      }
      const response = await claimUseCase.submitDataTransKlaim(null, data)
      if (response.error) {
        store.dispatch('hideLoading')
        $toast.add({
          severity: 'error',
          detail: $strInd.toast.errorSubmit,
          group: 'bc',
          life: 1500
        })
      } else {
      // Id.value = val.Id
        dataForm.kategoriKlaimId = null
        dataForm.nominal = 0
        dataForm.keterangan = null
        $toast.add({
          severity: 'success',
          detail: $strInd.toast.successSubmit,
          group: 'bc',
          life: 1500
        })
        emit('reloadData')
        closeSidebar()
      }
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProccess()
    }
    onMounted(() => {
      store.dispatch('showLoading')
      defineDataForm(dataEdit)
      getListKlaim()
    })

    return {
      store,
      route,
      Id,
      title,
      dataForm,
      v$,
      submitted,
      listKategori,
      closeSidebar,
      submitData
    }
  }

}
