import {
  ref,
  onMounted,
  getCurrentInstance,
  defineComponent
} from 'vue'
import {
  useRouter,
  useRoute
} from 'vue-router'
import ClaimAndPaymentForm from '@/views/master/claim-and-insurance/claim-and-payment/ClaimAndPaymentForm.vue'
import {
  claimUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import moment from 'moment'
import { checkPermission } from '@/utils/helpers/checkPermission'
import PermissionEnum from '@/utils/enum/permissions'

export default defineComponent({
  name: 'ClaimAndPayment',
  components: {
    ClaimAndPaymentForm
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const op = ref()
    const dataEdit = ref(null) as any
    const sliderActive = ref(false)
    const data = ref([])
    const dataHistory = ref([])
    const filterCal = ref()
    const sendDateFilter = ref(false)
    const dateFilter = ref(null)
    const inputSearch = ref('')
    const filters = {
      search: '',
      totalRecords: 0,
      pageNumber: 1
    }
    const isPaymentKlaim = ref(false)
    const showDialogHistory = ref(false)

    const addedKlaimAgent = (val: any) => {
      isPaymentKlaim.value = false
      sliderActive.value = true
      dataEdit.value = val
    }

    const paymentKlaim = (val: any) => {
      console.log('xxx')
      isPaymentKlaim.value = true
      sliderActive.value = true
      dataEdit.value = val
    }

    const showDropDown = (evt: any, val: any) => {
      dataEdit.value = val
      op.value.toggle(evt)
    }

    const hideDialogHistory = () => {
      showDialogHistory.value = false
    }

    const colorAndBgHistory = (IsPayment: any) => {
      let bg
      let color
      if (IsPayment) {
        bg = 'background-green-lighten-5'
        color = 'color-green'
      } else {
        bg = 'background-red-lighten-5'
        color = 'color-red'
      }
      return {
        bg,
        color
      }
    }

    const getHistory = async (idAgent: any) => {
      store.dispatch('showLoading')
      const filter = {
        filter: `AgenId eq ${idAgent} ${dateFilter.value ? `and TanggalKlaim eq ${moment(dateFilter.value).format('YYYY-MM-DD')}` : ''}`,
      }
      const response = await claimUseCase.getAllTransKlaim(filter)
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: $strInd.toast.errorAllData,
          group: 'bc',
          life: 1500
        })
      } else {
        dataHistory.value = response.result
      }
      store.dispatch('hideLoading')
    }

    const getAllData = async (search: any = '', pageNumber = 1) => {
      filters.search = search
      filters.pageNumber = pageNumber
      const response = await claimUseCase.getTransLastKlaim(filters)
      // console.log('res', response)
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: $strInd.toast.errorAllData,
          group: 'bc',
          life: 1500
        })
      } else {
        data.value = response.result.Data
        filters.totalRecords = response.result.Count
      }
      store.dispatch('hideLoading')
    }

    const showingHistory = async (val: any) => {
      dataEdit.value = await val
      showDialogHistory.value = true
      getHistory(val.AgenId)
    }

    const closeSidebar = () => {
      sliderActive.value = false
    }
    const showFilterDate = (evt: any) => {
      filterCal.value.toggle(evt)
    }

    const saveFilterDate = () => {
      sendDateFilter.value = true
      getHistory(dataEdit.value.AgenId)
      filterCal.value.hide()
    }

    const clearFilter = () => {
      dateFilter.value = null
      sendDateFilter.value = false
      getHistory(dataEdit.value.AgenId)
      filterCal.value.hide()
    }

    onMounted(() => {
      store.dispatch('showLoading')
      getAllData()
    })

    const onPage = (event: any) => {
      filters.pageNumber = event.page + 1
      getAllData(filters.search, filters.pageNumber)
    }

    return {
      router,
      route,
      sliderActive,
      dataEdit,
      data,
      addedKlaimAgent,
      paymentKlaim,
      closeSidebar,
      inputSearch,
      op,
      showDropDown,
      isPaymentKlaim,
      getAllData,
      showDialogHistory,
      showingHistory,
      hideDialogHistory,
      colorAndBgHistory,
      dateFilter,
      filterCal,
      sendDateFilter,
      showFilterDate,
      clearFilter,
      saveFilterDate,
      dataHistory,
      moment,
      filters,
      onPage,
      checkPermission,
      PermissionEnum
    }
  },
})
